module.exports = {
    authActionTypes: require('./authActionTypes'),
    actActionTypes: require('./actActionTypes'),
    acePokerActionTypes: require('./acePokerActionTypes'),
    aceActionTypes: require('./aceActionTypes'),
    bingoTaskActionTypes: require('./bingoTaskActionTypes'),
    bingoConfigActionTypes: require('./bingoConfigActionTypes'),
    champagneActionTypes: require('./champagneActionTypes'),
    eventActionTypes: require('./eventActionTypes'),
    flashActionTypes: require('./flashActionTypes'),
    bundleActionTypes: require('./bundleActionTypes'),
    postActionTypes: require('./postActionTypes'),
    bannerActionTypes: require('./bannerActionTypes'),
    productActionTypes: require('./productActionTypes'),
    permissionActionTypes: require('./permissionActionTypes'),
    pointEventActionTypes: require('./PointEventActionTypes'),
    reportActionTypes: require('./reportActionTypes'),
    bingoBallActionTypes: require('./bingoBallActionTypes'),
    bingo3ActionTypes: require('./bingo3ActionTypes'),
    dragonActionTypes: require('./dragonActionTypes'),
    acePoker2ActionTypes: require('./acePoker2ActionTypes'),
    eventInfoActionTypes: require('./EventInfoActionTypes'),
    partyActionTypes: require('./partyActionTypes'),
    party3ActionTypes: require('./party3ActionTypes'),
    aceLotteryActionTypes: require('./aceLotteryActionTypes'),
    gorichmanActionTypes: require('./gorichmanActionTypes'),
    qaActionTypes: require('./qaActionTypes'),
    luxuryActionTypes: require('./luxuryActionTypes'),
    missionActionTypes: require('./missionActionTypes'),
    registerActionTypes: require('./registerActionTypes'),
    gameInforActionTypes: require('./gameInforActionTypes'),
    rankActionTypes: require('./rankActionTypes'),
    superGiveActionTypes: require('./superGiveActionTypes'),
    bossActionTypes: require('./bossActionTypes'),
    spinActionTypes: require('./spinActionTypes'),
    treasureActionTypes: require('./treasureActionTypes'),
    worldCupActionTypes: require('./worldCupActionTypes'),
    eggActionTypes: require('./eggActionTypes'),
    moneyGodActionTypes: require('./moneyGodActionTypes'),
    goldenTreeActionTypes: require('./goldenTreeActionTypes'),
    seafaringActionTypes: require('./seafaringActionTypes'),
    wishActionTypes: require('./wishActionTypes'),
    olympicActionTypes: require('./olympicActionTypes'),
    treatOrTrickActionTypes: require('./treatOrTrickActionTypes'),
};

const ActionTypes = {
    LOADING: 'treatOrTrick.loading',
    ERROR: 'treatOrTrick.error',
    SET_DATA: 'treatOrTrick.set_data',

    FETCH_OPEN_AWARD: 'treatOrTrick.fetchOpenAward',
    FETCH_BINGO_AWARD: 'treatOrTrick.fetchBingoAward',
    FETCH_PROGRESS_AWARD: 'treatOrTrick.fetchProgressAward',
    FETCH_RANK_LIST: 'treatOrTrick.fetchRankList',
};

module.exports = ActionTypes;
